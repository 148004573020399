import React from 'react'

import '../style/header.css';
import logo from "../asset/img/jw-logo.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <section id="header">
      <div className='gradient'>

      </div>
      <div className='gradient-image'>

      </div>
      <div className="">
        <div className="container header">
          <div className="head-logo">
            <Link to="/">
              <img
                src={logo}
                alt="Junior.Work Logo"
              />
            </Link>
          </div>
          <div className="head-button">
            <Link to="/subscribe" className="btn btn-green">Subscribe</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header