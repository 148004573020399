import React from 'react';
import logo from "../asset/img/jw-logo.png";
import '../style/footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div><footer className="contr">
            <div className="footer-notice">
                <span>
                     <Link to='/'>
                     <img src={logo} className="img-resp ft-logo black-logo " alt="Junior Work Logo" style={{ "marginTop": "0px", "maxWidth": "50px" }} />
                     </Link>
                </span>
                <span>Find your first web dev job at Junior.Work.</span>
            </div>
            <hr className="line-ft" />
            <div className="footer-notice">
                <span> Copyright {new Date().getFullYear()} Junior.Work. All rights reserved.</span>
                <span>
                    <Link to="/privacy" className="ft-link">Privacy Policy</Link>
                    </span>
            </div>
        </footer></div>
    )
}

export default Footer