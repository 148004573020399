import React, { useState, useEffect } from 'react'
import logo from "../asset/img/jw-logo.png";
import '../style/general.css'
import '../style/home.css';

import { Link, NavLink } from "react-router-dom";
import Job from '../components/Job';

function Home() {
    const [previewNumber, setPreviewNumber] = useState(3);
    const [previewText, setPreviewText] = useState("Show More");
    const loadMore = () => {
        if (previewNumber < 5) {
            setPreviewNumber(5)
        } else {
            setPreviewText("Subscribe to our email list for more jobs updates")
        }
    }
    const [jobs, setJobs] = useState([
        {
            "id": "recOs7LldtdSljqIn",
            "createdTime": "2022-09-05T00:14:57.000Z",
            "fields": {
                "name": "WordPress Developer",
                "logo": "https://asset.junior.work/img/juniorwork_logo.png",
                "company": "Junior.Work",
                "link": "https://junior.work/",
                "technologies": [
                    "HTML",
                    "CSS",
                    "Php",
                    "SEO"
                ],
                "promoted": true
            }
        },
        {
            "id": "recSpnFzosORDTAYd",
            "createdTime": "2022-09-05T00:14:57.000Z",
            "fields": {
                "name": "Web3 Full Stack Software Engineer Third",
                "logo": "https://asset.junior.work/img/juniorwork_logo.png",
                "company": "Junior.Work",
                "link": "https://junior.work/",
                "technologies": [
                    "HTML",
                    "CSS",
                    "WordPress"
                ]
            }
        },
        {
            "id": "rectSwODkFC39yeSo",
            "createdTime": "2022-09-05T00:14:57.000Z",
            "fields": {
                "name": "Junior React Developer",
                "logo": "https://asset.junior.work/img/juniorwork_logo.png",
                "company": "Junior.Work",
                "link": "https://junior.work/",
                "technologies": [
                    "React",
                    "Blockchain",
                    "Communication",
                    "C#"
                ]
            }
        }
    ]);



    useEffect(() => {
        fetch('https://late-tooth-9c19.woltez2034.workers.dev/')
           .then((resp) => resp.json())
            .then(data => {
                // this.setState({ movies: data.records });
                setJobs(data.records);
                console.log(data.records);

            }).catch(err => {
                // Error :(
            });
    }, [])

    return (
        <div>
            <section className='container' id="hero">
                <h1>Hey 👋! <br />
                    Are you looking to find your <br />
                    {/* first &lt;Web Dev&gt; */}
                    dream job on <span style={{ color: 'var(--green)' }}>Junior</span>.Work?  </h1>
            </section>

            <section>
                <div className="container">
                    <div className=" green-bg rounded-home ">
                        <div className="subscribe-home split">
                            <div>
                                <h2 className='text-white'>
                                    Subscribe for&nbsp;<u>free</u> to access
                                    curated&nbsp;list of 6 jobs<span className='asterix'>*</span>,
                                    crafter for junior&nbsp;developers.
                                    <span>*updated weekly</span>
                                </h2>
                            </div>

                            <div className='pretty-white-box'><div className="head-button">
                                <Link to="/subscribe" className="btn btn-green rounded-btn">Find My First Job ►</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='jobs'>
                <div className="container">
                    <div className='container text-center'><h2 className='h1'>Latest Jobs</h2></div>



                    {jobs.slice(0, previewNumber).map((job) => {
                        return <Job key={job.id} index={job.id} technologies={job.fields.technologies} link={job.fields.link} name={job.fields.name} logo={job.fields.logo} company={job.fields.company} promoted={job.fields.promoted} />
                    })}

                    <div className="load-more">
                        <div className={(previewNumber < 5) ? "btn btn-green" : "btn"} onClick={loadMore}> <strong>{previewText}</strong></div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Home