import "./style/general.css";

import Home from "./pages/Home";
import Header from "./pages/Header";
import Subscribe from "./pages/Subscribe";
import Footer from "./pages/Footer";
import Privacy from "./pages/Privacy";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Header></Header>
         <Routes>
          <Route path="/" element={<Home />} />
          <Route path="subscribe" element={<Subscribe />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
