import React from 'react'
import uuid from 'react-uuid';
import '../style/job.css'


function Job({ name, technologies, link, logo, company, promoted }) {
    return (
        <div className="job">
            <div className="job-description">
                <div className="job-title">
                    <h3>{name} at <span><img src={logo} alt={company} /></span>.</h3>
                </div>

                <div className="job-technologies">
                    {technologies.slice(0, 5).map((tech, index) => {
                        return <div key={uuid()} className="job-technology"><span className="job-tech">{tech}</span> </div>
                    })}
                </div>
            </div>
            <div className="job-actions">
                <div className="job-action">
                    <a href={link} target="_blank" >Apply ➜</a>
                </div>

                <div className="job-action">
                    {promoted ? <div className='remote' target="_blank" >Promoted </div> : ''}
                </div>
            </div>
        </div>
    )
}

export default Job