import React from 'react'


function Subscribe() {
  const emailForm = '<iframe width="540" height="820px" src="https://d48d886b.sibforms.com/serve/MUIEAOQBSbw7hVlCMUyd-un-2a-Kg5IBkIW4cyF5jO77iTsQRkFtQzaJVBWC16Z1uWJbZlu4yVIuyxgTbpfVwS9lC_m2xre-fZIeRc5UJSlW12e4aK4LxwHZDxz4DBAE0mYTJXcfx58Y6sk-uGpTbYC6fkdqgv6uIgfhCik0g2OkaKohjW1RCrq7hYe3JoOaYg3LEpS5n1hIaYkU" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>';
  return (
    <div id='subscribe' className='container'>
      <div dangerouslySetInnerHTML={{__html: emailForm}} />
    </div>
  )
}

export default Subscribe